import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				FAQ | Najczęściej zadawane pytania w Canvas Whisper Tattoo Parlor
			</title>
			<meta name={"description"} content={"Odpowiedzi tworzone z dbałością, tak jak nasze tatuaże"} />
			<meta property={"og:title"} content={"FAQ | Najczęściej zadawane pytania w Canvas Whisper Tattoo Parlor"} />
			<meta property={"og:description"} content={"Odpowiedzi tworzone z dbałością, tak jak nasze tatuaże"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65d461af0072400020ea53a5/images/4.jpg?v=2024-04-02T13:59:22.674Z"} />
			<link rel={"shortcut icon"} href={"https://quircosale.com/img/4287179.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://quircosale.com/img/4287179.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://quircosale.com/img/4287179.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://quircosale.com/img/4287179.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://quircosale.com/img/4287179.png"} />
			<meta name={"msapplication-TileImage"} content={"https://quircosale.com/img/4287179.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="100px 0 100px 0" background="#ffffff" quarkly-title="FAQ-11">
			<Text
				margin="0px 0px 70px 0px"
				font="normal 900 52px/1.2 --fontFamily-sans"
				color="#0e1940"
				text-align="center"
				lg-margin="0px 0px 50px 0px"
				sm-font="normal 900 36px/1.2 --fontFamily-sans"
			>
				Odpowiedzi na najczęściej zadawane pytania
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="16px 24px"
				lg-grid-template-columns="1fr"
			>
				<Box min-width="100px" min-height="100px">
					<Box
						min-width="100px"
						min-height="100px"
						border-width="1px"
						border-style="solid"
						border-color="--color-lightD2"
						border-radius="15px"
						padding="25px 25px 25px 25px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 16px 0px"
						sm-padding="18px 18px 18px 18px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans" color="#0e1940">
						Czy muszę umówić się na spotkanie?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet" color="#555a5f">
						Tak, działamy głównie po wcześniejszym umówieniu się, aby zapewnić każdemu klientowi dedykowany czas i uwagę. Wejścia są mile widziane w zależności od dostępności.
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						border-width="1px"
						border-style="solid"
						border-color="--color-lightD2"
						border-radius="15px"
						padding="25px 25px 25px 25px"
						sm-padding="18px 18px 18px 18px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans" color="#0e1940">
						Jak powinienem przygotować się do sesji tatuażu?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet" color="#555a5f">
						Bądź nawodniony, dobrze się odżywiaj przed wizytą i unikaj alkoholu lub leków rozrzedzających krew przez 24 godziny przed wizytą. Należy nosić wygodne ubranie, które zapewnia łatwy dostęp do tatuowanego obszaru.
						</Text>
					</Box>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Box
						min-width="100px"
						min-height="100px"
						border-width="1px"
						border-style="solid"
						border-color="--color-lightD2"
						border-radius="15px"
						padding="25px 25px 25px 25px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 16px 0px"
						sm-padding="18px 18px 18px 18px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans" color="#0e1940">
						Czy mogę przynieść własny projekt?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet" color="#555a5f">
						Jak najbardziej! Uwielbiamy pracować z projektami dostarczonymi przez klienta lub współpracować, aby udoskonalić Twój pomysł w idealny tatuaż.
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						border-width="1px"
						border-style="solid"
						border-color="--color-lightD2"
						border-radius="15px"
						padding="25px 25px 25px 25px"
						sm-padding="18px 18px 18px 18px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans" color="#0e1940">
						Czy tatuaże bolą?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet" color="#555a5f">
						Ból jest subiektywny i różni się w zależności od lokalizacji i indywidualnej tolerancji na ból. Staramy się, aby Twoje doświadczenie było jak najbardziej komfortowe.
						</Text>
					</Box>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Box
						min-width="100px"
						min-height="100px"
						border-width="1px"
						border-style="solid"
						border-color="--color-lightD2"
						border-radius="15px"
						padding="25px 25px 25px 25px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 16px 0px"
						sm-padding="18px 18px 18px 18px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans" color="#0e1940">
						Jak utrzymywana jest higiena w studio?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet" color="#555a5f">
						Twoje bezpieczeństwo jest naszym najwyższym priorytetem. Przestrzegamy ścisłych protokołów higieny, w tym sterylizowanego sprzętu, jednorazowych igieł i czystego, higienicznego środowiska.
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						border-width="1px"
						border-style="solid"
						border-color="--color-lightD2"
						border-radius="15px"
						padding="25px 25px 25px 25px"
						sm-padding="18px 18px 18px 18px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans" color="#0e1940">
						Jak długo trwa gojenie się tatuażu?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet" color="#555a5f">
						Czas gojenia jest różny, ale większość tatuaży potrzebuje około 2 do 4 tygodni, aby zagoić się na powierzchni i do 6 miesięcy w pełni wewnątrz. Zapewniamy szczegółowe instrukcje dotyczące pielęgnacji, aby zapewnić optymalne gojenie.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Nadal jesteś ciekawy?
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Jeśli Twoja ciekawość nie została zaspokojona lub masz bardziej szczegółowe pytania, z przyjemnością udzielimy odpowiedzi, których szukasz. Skontaktuj się z nami bezpośrednio przez telefon, e-mail lub odwiedź nas osobiście, aby porozmawiać. W Canvas Whisper Tattoo Parlor jesteśmy tutaj, aby zapewnić, że Twoja podróż do świata tatuaży będzie tak płynna i przyjemna, jak to tylko możliwe.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://uploads.quarkly.io/65d461af0072400020ea53a5/images/3.jpg?v=2024-04-02T13:59:22.645Z"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});